.skillsContainer {
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

.title {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 20px;
}

.subtitle {
  text-align: center;
  font-size: 1.2rem;
  color: #7f8c8d;
  max-width: 600px;
  margin: 0 auto 40px;
}

.skillsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  margin-bottom: 40px;
}

.skillsColumn {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.skillsTitle {
  font-size: 1.5rem;
  color: #3498db;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.skillBar {
  margin-bottom: 20px;
}

.skillInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #7f8c8d;
}

.skillProgress {
  height: 10px;
  background-color: #ecf0f1;
  border-radius: 5px;
  overflow: hidden;
}

.skillLevel {
  height: 100%;
  background-color: #3498db;
  border-radius: 5px;
  transition: width 1s ease-in-out;
}

.additionalSkills {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.skillsList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  list-style-type: none;
  padding: 0;
}

.skillsList li {
  position: relative;
  padding-left: 25px;
  font-size: 1rem;
  color: #34495e;
}

.skillsList li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #3498db;
  font-weight: bold;
}

.quote {
  text-align: center;
  font-style: italic;
  color: #7f8c8d;
  max-width: 800px;
  margin: 0 auto;
}

.quote blockquote {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.quote cite {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .skillsGrid {
    grid-template-columns: 1fr;
  }
  
  .title {
    font-size: 2rem;
  }
  
  .subtitle {
    font-size: 1rem;
  }
  
  .skillsList {
    grid-template-columns: 1fr;
  }
}