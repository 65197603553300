.contactContainer {
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

.title {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 20px;
}

.subtitle {
  text-align: center;
  font-size: 1.2rem;
  color: #7f8c8d;
  max-width: 600px;
  margin: 0 auto 40px;
}

.contactGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 60px;
}

.contactItem {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contactItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.icon {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  color: #3498db;
}

.contactItem h2 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 10px;
}

.contactItem p,
.contactItem a {
  font-size: 1.1rem;
  color: #34495e;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contactItem a:hover {
  color: #3498db;
}

.ctaSection {
  text-align: center;
  background-color: #3498db;
  color: #fff;
  padding: 40px;
  border-radius: 10px;
  margin-top: 40px;
}

.ctaSection h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.ctaSection p {
  font-size: 1.1rem;
  margin-bottom: 30px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.ctaButton {
  display: inline-block;
  background-color: #fff;
  color: #3498db;
  padding: 12px 24px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
}

.ctaButton:hover {
  background-color: #2980b9;
  color: #fff;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .contactGrid {
    grid-template-columns: 1fr;
  }
  
  .title {
    font-size: 2rem;
  }
  
  .subtitle, .ctaSection p {
    font-size: 1rem;
  }
  
  .ctaSection h2 {
    font-size: 1.5rem;
  }
}