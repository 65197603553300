.hobbies {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

.title {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 20px;
}

.intro {
  text-align: center;
  font-size: 1.2rem;
  color: #7f8c8d;
  max-width: 800px;
  margin: 0 auto 40px;
}

.hobbyGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  margin-bottom: 40px;
}

.hobbyItem {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hobbyItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.hobbyImage {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.hobbyItem:hover .hobbyImage {
  transform: scale(1.05);
}

.hobbyContent {
  padding: 20px;
}

.hobbyContent h2 {
  font-size: 1.5rem;
  color: #3498db;
  margin-bottom: 10px;
}

.hobbyContent p {
  font-size: 1rem;
  line-height: 1.6;
}

.conclusion {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  font-size: 1.1rem;
  color: #34495e;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .hobbyGrid {
    grid-template-columns: 1fr;
  }
  
  .title {
    font-size: 2rem;
  }
  
  .intro, .conclusion {
    font-size: 1rem;
  }
}