
.footer {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 40px 0 20px;
  font-family: 'Arial', sans-serif;
}

.footerContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footerSection {
  flex: 1;
  min-width: 200px;
  margin-bottom: 20px;
}

.footerSection h3 {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #3498db;
}

.footerSection p {
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.footerSection ul {
  list-style-type: none;
  padding: 0;
}

.footerSection ul li {
  margin-bottom: 8px;
}

.footerSection ul li a {
  color: #ecf0f1;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footerSection ul li a:hover {
  color: #3498db;
}

.socialLinks {
  display: flex;
  gap: 15px;
}

.socialIcon {
  color: #ecf0f1;
  transition: color 0.3s ease;
}

.socialIcon:hover {
  color: #3498db;
}

.footerBottom {
  text-align: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #34495e;
}

.footerBottom p {
  font-size: 0.8rem;
  color: #bdc3c7;
}

@media (max-width: 768px) {
  .footerContent {
    flex-direction: column;
  }

  .footerSection {
    margin-bottom: 30px;
  }
}